import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Error from "./Error";
import { ArrowBackIcon, TaskCompleteIcon } from "./SVGs";

// Client and server side recaptcha verification
// https://code.tutsplus.com/tutorials/example-of-how-to-add-google-recaptcha-v3-to-a-php-form--cms-33752

const Contact = ({ setShowContactSlideOut, isSlideOut }) => {
  // https://css-tricks.com/headless-form-submission-with-the-wordpress-rest-api/

  // useEffect(() => {
  //   fetch(
  //     "https://wc2.eventplatforms.online//wp-json/contact-form-7/v1/contact-forms/72/"
  //   )
  //     .then((blob) => blob.json())
  //     .then((json) => console.log(json));
  // }, []);

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const latestErrors = useRef(errors);
  const closeOnSuccessTimeout = useRef();

  const removeError = (id) => {
    const newErrors = [...latestErrors.current];
    const index = errors.map((err) => err.id).indexOf(id);
    newErrors.splice(index, 1);
    setErrors(newErrors);
  };

  useEffect(() => {
    return clearTimeout(closeOnSuccessTimeout.current);
  }, []);

  const handeSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const url =
      "https://wc2.eventplatforms.online//wp-json/contact-form-7/v1/contact-forms/72/feedback";
    const form = e.target,
      body = new FormData(form);

    fetch(url, {
      method: "POST",
      body,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        // Determine if the submission is not valid
        if (res.status === "validation_failed") {
          setLoading(false);
          // Inform the user there has been a validation error
          const newErrors = [...errors];
          const err = { message: res.message, id: Date.now() };
          newErrors.push(err);
          setErrors(newErrors);
        }
        // Handle the happy path
        if (res.status === "mail_sent") {
          setLoading(false);
          setSuccess(true);
          e.target.reset();
          closeOnSuccessTimeout.current = setTimeout(() => {
            setShowContactSlideOut(false);
          }, 5000);
        }
      })
      .catch((err) => {
        setLoading(false);
        // Handle the case when there's a problem with the request
        setErrors([
          ...errors,
          {
            id: Date.now(),
            message: "Whoops, something went wrong. Please try again later",
          },
        ]);
      });
  };

  return (
    <Container>
      <div className="top">
        {isSlideOut && (
          <ArrowBackIcon onClick={() => setShowContactSlideOut(false)} />
        )}
      </div>
      <div className="middle">
        <h2>Get in Touch</h2>

        <div className="errors">
          {errors.map((error) => {
            return (
              <Error
                key={error.id}
                error={error}
                errors={errors}
                removeError={removeError}
              />
            );
          })}
        </div>
        <Form onSubmit={(e) => handeSubmit(e)} success={success}>
          <fieldset disabled={loading}>
            <div className="input-wrapper">
              <label htmlFor="full-name">
                Name <span className="subtext">required</span>
              </label>
              <input type="text" name="full-name" required id="full-name" />
            </div>

            <div className="input-wrapper">
              <label htmlFor="email">
                Email <span className="subtext">required</span>
              </label>
              <input type="email" name="email" required id="email" />
            </div>

            <div className="input-wrapper">
              <label htmlFor="phone">Phone</label>
              <input type="tel" name="phone" id="phone" />
            </div>
            <div className="input-wrapper">
              <label htmlFor="message">
                Message <span className="subtext">required</span>
              </label>
              <textarea name="message" id="message" rows="8" />
            </div>
            <div className="input-wrapper">
              <input type="submit" value="SUBMIT" />
            </div>
          </fieldset>
          {success && (
            <div className="success">
              <TaskCompleteIcon className="complete" />
              <div className="message">Your message has been sent</div>
            </div>
          )}
        </Form>
      </div>
      <div className="bottom"></div>
      <div className="bottom"></div>
    </Container>
  );
};

export default Contact;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #fff;

  .top,
  .middle,
  .bottom {
    width: 100%;
  }

  .middle {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .top {
    margin: 2em ​1em 0 4em;

    svg {
      fill: #fff;
      width: 4em;
      height: 4em;
      cursor: pointer;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  position: relative;

  fieldset {
    border: none;
    opacity: ${(props) => (props.success ? "0.5" : 1)};
    &:disabled {
      opacity: 0.5;
    }
  }

  .input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0.5em 0;

    input,
    textarea {
      width: 100%;
      padding: 0.5em;
      border: 0;
      border-radius: 5px;
      font-weight: 600;
      color: #ea4f4f;
    }

    label {
      text-transform: uppercase;
      color: #fff;
      font-weight: 600;

      .subtext {
        font-weight: 200;
        font-size: 0.75em;
        text-transform: lowercase;
        padding-left: 0.5em;
      }
    }
  }

  input[type="submit"] {
    background: #3f83db;
    color: #fff;
    border: 2px solid;
    cursor: pointer;
  }

  .success {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    justify-content: center;
    align-items: center;

    .message {
      font-size: 1.5em;
      font-weight: 400;
      text-align: center;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
  }

  .complete {
    width: 10em;
    height: 10em;
    path {
      stroke: #3f83db;
      fill: #fff;
      stroke-width: 1px;
    }
  }
`;
